import { auth } from '@/services/auth'
import { WEGLOT_API_KEY, WEGLOT_ALLOWED_LANGS } from '@/init/settings'
import { waitTimer } from '@/helpers/timer'

export function isAllowedLanguage(
  language: string | null | undefined,
): boolean {
  if (!language) {
    return false
  }
  return WEGLOT_ALLOWED_LANGS.includes(language)
}

/** Detect the language depending on the firstUrl.
 *
 * @param firstUrl - The first URL of the user
 * @returns The ISO 639-1 2-letter code of a language (or null if not found).
 */
function detectFirstUrlLanguage(firstUrl: string | null): string | null {
  if (firstUrl === null) {
    return null
  }

  // Summary and PDF public pages
  const regexSummaryPdf = /shortform\.com\/([a-z]{2})\/(summary|pdf)\/.+/
  const matchSummaryPdf = firstUrl.match(regexSummaryPdf)
  if (matchSummaryPdf) {
    return matchSummaryPdf[1] // Return the captured language code
  }

  // Blog posts
  const regexBlog = /shortform\.com\/blog\/([a-z]{2})\/.+/
  const matchBlog = firstUrl.match(regexBlog)
  if (matchBlog) {
    return matchBlog[1] // Return the captured language code
  }

  return null
}

/**
 * Get the selectors that should be merged by Weglot.
 *
 * Weglot merges some of the sequential HTML elements, such as `a` and `span`
 * so that the text is not broken into multiple pieces.
 * For example, something like <a>Click <span>here</span></a> will be
 * translated as one "Click here" item.
 *
 * This is useful, but sometimes gets in the way because it may break
 * Vue reactivity and event handlers.
 * For example, if we have several `a` elements in a row, such as in the
 * top app menu, Weglot will merge them, translate and then replace
 * with translated HTML all at once, and Vue router clicks stop working.
 *
 * To avoid this, we can use the `wg-no-merge` class to avoid merging.
 *
 * Also note that the `merged_selectors_remove` option is not documented,
 * I found it in the weglot script source code.
 * An alternative (hacky) way is to wrap the content inside `a` into `p`
 * or `div`, for example `<a><p>Click here</p></a>`.
 *
 * See also:
 * https://developers.weglot.com/javascript/advanced-concepts/translation-engines
 * https://developers.weglot.com/javascript/options
 * https://github.com/shortformhq/main/issues/14700
 */
function weglotMergedSelectorsRemove(): { value: string }[] {
  // Note that we could use multiple custom classes, in the array
  // below like `.app-menu-item`, `.something-else`, etc., but it's
  // better to use one class for all such cases, this way we can easily
  // find all such cases in the code.
  return [{ value: '.wg-no-merge' }]
}

export function initWeglotIfNeeded(): void {
  const language = auth.loggedIn()
    ? auth.getUser()?.language
    : detectFirstUrlLanguage(localStorage.getItem('sf_first_page'))

  // Init Weglot only if the language is specified.
  if (isAllowedLanguage(language)) {
    const weglot = (window as any).Weglot
    if (weglot && !weglot.initialized) {
      console.log(`Weglot initialization (${language})...`)

      // Set callback on the "initialized" event.
      weglot.on('initialized', () => {
        weglot.switchTo(language)
        const event = new Event('weglot_initialized')
        window.dispatchEvent(event)
      })

      weglot.initialize({
        api_key: WEGLOT_API_KEY,
        // See the explanation option above, in the function docstring.
        merged_selectors_remove: weglotMergedSelectorsRemove(),
      })
    }
  }
}

export async function waitWeglotInitialization(
  timeout: number = 5000,
  step: number = 100,
): Promise<boolean> {
  const weglot = (window as any).Weglot
  if (!weglot) {
    return false
  }
  while (!weglot.initialized && timeout > 0) {
    await waitTimer(step)
    timeout -= step
  }

  return weglot.initialized
}

export function isContentTranslated(): boolean {
  const weglot = (window as any).Weglot
  if (!weglot || !weglot.initialized) {
    return false
  }
  const currentLang = weglot.getCurrentLang()
  const result =
    isAllowedLanguage(currentLang) &&
    currentLang !== weglot.options.language_from
  return result
}

export function getAvailableLanguages(): string[] {
  const weglot = (window as any).Weglot
  return [weglot.options.language_from, ...WEGLOT_ALLOWED_LANGS]
}

export function weglotInitialized(): boolean {
  const weglot = (window as any).Weglot
  if (!weglot || !weglot.initialized) {
    return false
  }
  return true
}

/**
 * Wrapper for the Weglot.search() function to call it via async/await.
 *
 * @param translatedTerm - The user input (search term) on a translated language.
 * @returns - The translated to English search term,
 * so we can pass it to the backend for search.
 */
async function weglotSearch(translatedTerm: string): Promise<string> {
  return new Promise((resolve, reject) => {
    Weglot.search(translatedTerm, (term: string) => {
      if (term) {
        resolve(term)
      } else {
        reject('Translation failed')
      }
    })
  })
}

/**
 * Translate a user input (search term) to the English (language_from),
 * so we can pass it to the backend for search.
 *
 * If Weglot is not initialized or the current language is English,
 * we will just return the input term.
 *
 * @param term - The user input (search term) on a translated language.
 * @returns - The translated to English search term,
 * so we can pass it to the backend for search.
 */
export async function weglotTranslateToLanguageFrom(
  term: string,
): Promise<string> {
  if (term && isContentTranslated()) {
    term = await weglotSearch(term)
  }
  return term
}

/**
 * Parse the given `text` and tweak its html to work better with Weglot if translations are active.
 */
export function parseTextForTranslation(text: string): string {
  if (!isContentTranslated()) {
    return text
  }

  // The regex patterns of the strings we want to prevent from being merged
  // in the Weglot translations.
  const patterns = [
    // "Shortform <noun>" (eg. "Shortform Note:" or "Shortform Explainer").
    'Shortform\\s\\w+:?',
    // "--" or "—" (em dash) from sentences like "lorem--ipsum".
    '--|—',
    // Commas or periods preceded by a closing tag (eg. "</strong>, lorem").
    // The first group in this regex is a positive look behind (?<=...) that
    // matches ending tags (</anyWordCharacter>) but doesn't capture it. The
    // second group matches commas or periods.
    '(?<=<\\/\\w+>)(,|\\.)',
  ]
  const patternsRegex = new RegExp(patterns.join('|'), 'g')

  return preventMerge(text, patternsRegex)
}

/**
 * Wrap all matches of the given `pattern` in the given `text`
 * in spans with the `wg-no-merge` class.
 *
 * This is used to isolate these matches so that they are easier to
 * translate in the Weglot dashboard.
 */
export function preventMerge(text: string, pattern: RegExp): string {
  return text.replace(pattern, '<span class="wg-no-merge">$&</span>')
}
