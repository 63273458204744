
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'
import HighlightsByBook from '@/app/components/book/highlights/HighlightsByBook.vue'
import ExportHighlightsButton from '@/app/components/book/highlights/ExportHighlightsButton.vue'
import HeaderRoot from '@/app/components/header/Root.vue'

import Layout from '@/app/components/Layout.vue' // @ is an alias to /src
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import { bookmarks14994Experiment } from '@/services/ab'
@Options({
  components: {
    Layout,
    HeaderRoot,
    'v-wait': vLoading,
    LoadingSpinner,
    HighlightsByBook,
    ExportHighlightsButton,
  },
})
export default class HighlightsAll extends Vue {
  // Function for unit-testing purposes only (works only in unit-tests).
  // Checking that unhandled errors are caught by Sentry.
  _unitTestsThrowError4Sentry(): void {
    if ((window as any)['__UNIT_TESTING']) {
      throw new Error('Unhandled error should be caught by Sentry')
    }
  }

  get title(): string {
    return bookmarks14994Experiment()
      ? 'Highlights, notes and bookmarks'
      : 'Highlights and Notes'
  }
}
