import {
  QuestionnaireSection,
  OnboardingIntroPage,
  OnboardingTrialPage,
} from './long.onboarding.types'

const introPages: OnboardingIntroPage[] = [
  {
    title: '#1 most downloaded <span>book summary</span> app',
    subtitle: 'SimilarWeb, Nov 2024, worldwide',
    image: 'intro-1.svg',
  },
  {
    title: 'Key ideas from the <span>nonfiction bestsellers</span>',
    image: 'intro-2.svg',
  },
  {
    title: '<span>Listen or read</span> on the go & grow',
    image: 'intro-3.svg',
  },
]

const questionnairePages: QuestionnaireSection[] = [
  {
    title: '_intro',
    id: '_intro',
    pages: [
      {
        id: 'intro',
        type: 'Text',
        title: 'Glad to have you with us!',
        variableTitle: 'Glad to have you with us, <span>{{name}}!</span>',
        subtitle: 'Let your journey begin',
        image: {
          src: 'hand.png',
          width: 59,
          height: 59,
        },
        imageAnimation: 'wave',
        textSize: 'large',
        textAlignment: 'left',
      },
    ],
  },
  {
    id: 'about-you',
    title: 'About you',
    pages: [
      {
        id: 'focus',
        type: 'Options',
        title: "What's your focus right now?",
        options: [
          {
            label: 'Develop my career skills',
          },
          {
            label: 'Practice self-discipline & consistency',
          },
          {
            label: 'Understand myself & others better',
          },
          {
            label: 'Broaden my knowledge',
          },
          {
            label: "I don't relate to any of these",
            type: 'text-input',
            placeholder: 'Could you tell us about your focus?',
          },
        ],
      },
      {
        id: 'gender',
        type: 'Options',
        title: 'Select your gender',
        wrapInputs: true,
        options: [
          {
            label: 'Male',
          },
          {
            label: 'Female',
          },
          {
            label: 'Non-binary',
          },
          {
            label: 'Prefer not to say',
            type: 'skip',
          },
        ],
      },
      {
        id: 'age',
        type: 'Options',
        title: 'What is your age?',
        centerText: true,
        options: [
          { label: '18-24' },
          { label: '25-34' },
          { label: '35-44' },
          { label: '45-54' },
          { label: '55+' },
        ],
      },
    ],
  },
  {
    id: 'aspiration',
    title: 'Aspiration',
    pages: [
      {
        id: 'goals',
        type: 'Options',
        title: 'What are your goals?',
        subtitle:
          'Choose up to 3 goals. You can always change goals when they become irrelevant',
        multipleOptions: true,
        options: [
          {
            icon: 'stopwatch.svg',
            label: 'Increase productivity',
          },
          {
            icon: 'mountain.svg',
            label: 'Have a successful career',
          },
          {
            icon: 'ladybugs.svg',
            label: 'Be a better parent',
          },
          {
            icon: 'trophy.svg',
            label: 'Become confident',
          },
          {
            icon: 'balance.svg',
            label: 'Achieve life balance',
          },
          {
            icon: 'brain.svg',
            label: 'Boost intelligence',
          },
          {
            icon: 'heart.svg',
            label: 'Develop healthy relationships',
          },
          {
            icon: 'money.svg',
            label: 'Create wealth',
          },
          {
            icon: 'bed.svg',
            label: 'Improve sex life',
          },
          {
            icon: 'apple.svg',
            label: 'Lead a healthy lifestyle',
          },
          {
            icon: 'happy.svg',
            label: 'Reach happiness',
          },
        ],
      },
      {
        id: 'great-job',
        type: 'Text',
        title: 'Great job!',
        variableTitle: 'Great job, {{name}}!',
        subtitle:
          'You unlocked the Star Shooter achievement for aiming high and setting your goals',
        image: {
          src: 'great-job.svg',
          width: 257,
          height: 291,
        },
        imageAnimation: 'slide-up',
        textSize: 'large',
      },
      {
        id: 'book-summary-question',
        type: 'Options',
        title: 'Do you know what a book summary is?',
        centerText: true,
        options: [{ label: 'Yes' }, { label: 'No' }, { label: 'Not sure' }],
      },
      {
        id: 'comparison',
        type: 'Text',
        title:
          'Shortform helps you <span>reach your goals efficiently</span> with bite-sized summaries',
        image: {
          src: 'comparison.svg',
          width: 327,
          height: 235,
        },
      },
      {
        id: 'audio',
        type: 'Text',
        title:
          'And you can grow on the go by listening to <span>audio summaries</span>',
        image: {
          src: 'audio.svg',
          width: 375,
          height: 340,
        },
      },
      {
        id: 'daily-goal',
        type: 'DailyGoal',
        title: 'Choose your daily goal',
        subtitle: 'You can change it later',
      },
      {
        id: 'commit',
        type: 'Options',
        title: 'Commit to growing with Shortform',
        image: {
          src: 'growth.svg',
          width: 407,
          height: 209,
        },
        options: [
          {
            label: '7-day streak',
            hint: 'Promising',
          },
          {
            label: '14-day streak',
            hint: 'Determined',
          },
          {
            label: '30-day streak',
            hint: 'Impressive',
          },
          {
            label: '50-day streak',
            hint: 'Unstoppable',
          },
        ],
      },
      {
        id: 'reading-when',
        type: 'Options',
        title: 'When do you like to find out new ideas?',
        options: [
          {
            icon: 'sun.svg',
            label: 'After morning coffee',
          },
          {
            icon: 'road.svg',
            label: 'While commuting',
          },
          {
            icon: 'meal.svg',
            label: 'During my lunch break',
          },
          {
            icon: 'moon.svg',
            label: 'Before going to sleep',
          },
          {
            icon: 'relax.svg',
            label: 'Any spare time',
          },
        ],
      },
      {
        id: 'notifications',
        type: 'Notifications',
        title: 'Our notification system can help you',
        image: {
          src: 'notifications.svg',
          width: 308,
          height: 156,
        },
        options: [
          {
            title: 'Morning learning',
            subtitle:
              'Get reminders to repeat book ideas and get inspired by daily insights.',
          },
          {
            title: 'Stay on track',
            subtitle:
              'Remember to hit your daily reading goal and never lose your streak.',
          },
          {
            title: 'Up to date',
            subtitle: "We'll recommend you new gems based on your interests.",
          },
        ],
      },
      {
        id: 'review',
        type: 'Review',
        title:
          'More than <span>1 million customers trust</span> Shortform guides',
        review:
          "I LOVE Shortform as these are THE BEST summaries I've ever seen … and I've looked at lots of similar sites – like Blinkist and Get Abstracts and Soundview. first time I've ever found smart concise (yet detailed) book summaries that I can read on my phone as I walk on the treadmill",
        reviewer: 'Sophie Moore',
      },
    ],
  },
  {
    id: 'like-time',
    title: 'Like time',
    pages: [
      {
        id: 'statements',
        type: 'Statements',
        title: 'Do you relate to the statement?',
        hideContinueButton: true,
        content: [
          "I enjoy reading, but usually, it's hard for me to finish whole books",
          'I value self-growth, but I may lack motivation to find time for it',
          'I believe that personal growth should bring pleasure, not struggle',
        ],
      },
      {
        id: 'people-grow',
        type: 'Text',
        title: '<span>1M+ people</span> grow with Shortform',
        subtitle: `You've come to the right place because it's our mission to help you achieve your goals <span class="source">SimilarWeb, Nov 2024, worldwide</span>`,
        image: {
          src: 'world.svg',
          width: 344,
          height: 246,
        },
        textAlignment: 'left',
      },
      {
        id: 'books',
        type: 'Books',
        title: 'How interested are you in this book?',
        hideContinueButton: true,
        content: [
          'the-7-habits-of-highly-effective-people',
          'make-your-bed',
          'how-to-talk-to-anyone',
          'the-subtle-art-of-not-giving-a-f-ck',
          'atomic-habits',
        ],
      },
    ],
  },
  {
    id: '_outro',
    title: '_outro',
    pages: [
      {
        id: 'finishing-setup',
        type: 'FinishingSetup',
        title: 'Your <span>learning experience</span> is up and running',
        titleLoading:
          'We are crafting your <span>learning experience...</span>',
        hideContinueButton: true,
        steps: [
          {
            title: 'Goals',
            titleLoading: 'Setting goals',
            question: 'Do you procrastinate often?',
          },
          {
            title: 'Growth areas',
            titleLoading: 'Adapting growth areas',
            question: 'Do you want to give up when things get hard?',
          },
          {
            title: 'Content',
            titleLoading: 'Picking content',
            question: 'Do you learn by listening?',
          },
        ],
      },
      {
        id: 'commitment-pact',
        type: 'CommitmentPact',
        title: 'Commitment pact',
        textInitial:
          'To achieve what I want,<br>I must grow.<br>So, I commit to my goals and becoming better.<br>And I trust Shortform to guide me along the way and help me accomplish all my resolutions.',
        textFinal:
          "Yes, it's future you. I'm writing from {{futureYear}} because today is an important day for you. For us!<br>{{currentDate}} is the day <strong>we changed our lives.</strong><br>Now, I'm happier and using my potential to its fullest. Thanks to the self-growth choice you're about to make.",
        hideContinueButton: true,
      },
    ],
  },
]

const trialPages: OnboardingTrialPage[] = [
  {
    title: 'Free trial guide',
    steps: [
      {
        text: 'No payment now, just full access to the Premium experience',
        icon: 'lock.svg',
        day: 'Today',
      },
      {
        text: 'Get a reminder about the end date of your free trial',
        icon: 'bell.svg',
        day: 'Day 3',
      },
      {
        text: 'Cancel in advance to avoid payment',
        icon: 'rocket.svg',
        day: 'Day 5',
      },
    ],
  },
  {
    title:
      'We’ll send a reminder <span>2 days</span> before your free trial ends',
    image: {
      src: 'trial.svg',
      width: 201,
      height: 217,
    },
  },
]

export const onboardingPages = {
  get intro(): OnboardingIntroPage[] {
    return introPages
  },
  get questionnaire(): QuestionnaireSection[] {
    return questionnairePages
  },
  get trial(): OnboardingTrialPage[] {
    return trialPages
  },
}
