import { DocView } from './doc'
import { ContentView } from '@/models/doc'
import { platform } from '@/services/platform'
import { scrollIntoView } from '@/helpers/dom'
import { emitter } from '@/services/mitt'
import { Bookmark } from './interfaces'

/**
 * Table of Contents logic for reading page.
 */
export class DocTocView {
  private doc: DocView

  // Controls TOC visibility
  private _isVisible: boolean = false

  // Controls "Full Summary" submenu visibility
  private _submenuVisible: boolean = true
  private _hasFullSummary: boolean = false

  constructor(doc: DocView) {
    this.doc = doc

    // The doc content always has at least 1 item
    // (the 1-page summary), so we check if there's
    // anything beyond that to display the full summary.
    if (this.doc.content.length > 1) {
      this._hasFullSummary = true
    }
  }

  /**
   * Init event listeners.
   *
   * Note: this must not be done in the constructor, otherwise
   * Vue does not have chance to setup its reactivity system
   * and `this` inside event listeners is not reactive.
   * See #4824.
   */
  public async init(): Promise<void> {
    emitter.on('closeOverlayMenu', () => {
      this.hide()
    })
  }

  /**
   * Restore TOC position.
   *
   * When we open the TOC we need to see the current chapter
   * in the browser viewport. See #707 for details.
   *
   * This method is called before we show the TOC and it does the following:
   * - Get the current chapter and check if it's inside the Full Summary
   * - Unfold the full summary, wait for the transition, scroll into the chapter view
   */
  private _restorePosition(): void {
    const currentChapter = document.querySelector(
      '.control-menu__sub-unit--active',
    )
    // Some chapter from Full Summary is open
    if (this.doc.currentPage.order > 1 && currentChapter !== null) {
      // Unfold Full Summary
      this._submenuVisible = true

      // Wait for transition and scroll to the current chapter
      // The transition length is 0.3s, so we add 10 more milliseconds
      // to make sure it's finished.
      setTimeout(
        () =>
          scrollIntoView(currentChapter as HTMLElement, {
            behavior: 'auto',
            block: 'center',
            inline: 'center',
          }),
        310,
      )
    }
  }

  /**
   * Return ``true`` if the table of contents visible.
   */
  public get isVisible(): boolean {
    return this._isVisible
  }

  /**
   * Show the table of contents control.
   */
  public show(): void {
    this._restorePosition()

    this._isVisible = true
    emitter.emit('overlayMenuOpened')
  }

  /**
   * Click "outside" the TOC.
   *
   * Actually there's no "outside" because we show the `div.control-menu` that's on top
   * of everything else, so we treat the click on it as "outside". Also, this element is
   * a parent element for TOC, so we need to check whether the click is exactly on it.
   */
  public clickOutside(event: Event): void {
    const targetClass = (event.target as any).className
    if (targetClass === 'control-menu') {
      this.hide()
    }
  }

  /**
   * Hide the table of contents control.
   */
  public hide(): void {
    this._isVisible = false
    emitter.emit('overlayMenuClosed')
  }

  /**
   * Return ``true`` if the doc has Full Summary section.
   */
  public hasFullSummary(): boolean {
    return this._hasFullSummary
  }

  /**
   * Return the CSS class for the doc chapter icon.
   * - completed chapter or exercise: isummary-completed
   * - not completed chapter or exercise: baseCss
   *   (isummary-chapter, isummary-exercise, etc)
   */
  private _chapterCssClass(
    baseCss: string,
    active: boolean,
    completed: boolean,
  ): string {
    let css = baseCss
    if (completed) {
      css = 'isummary-completed'
    }
    if (active) {
      css += ' active'
    }
    return css
  }

  /**
   * Return the CSS class for "1-Page Summary" item icon.
   */
  public onePageSummaryCss(): string {
    const active = this.doc.isCurrentPage(this.doc.pages[0])
    const completed = this.doc.isCompletedPage(this.doc.pages[0])
    return this._chapterCssClass('isummary-1-page', active, completed)
  }

  /**
   * Return the link for "1-Page Summary" item.
   */
  public onePageSummaryLink(): Record<string, unknown> {
    return {
      name: this.doc.routeNames().page,
      params: {
        url_slug: this.doc.url_slug,
      },
    }
  }

  /**
   * Return the CSS class for "1-Page Summary" item wrapper.
   */
  public onePageSummaryWrapperCss(): string {
    if (this.doc.isCurrentFirstPage()) {
      return 'control-menu__unit--active'
    }
    return ''
  }

  /**
   * Return the CSS class for "Full Summary" item icon.
   */
  public fullSummaryCss(): string {
    const active = this.doc.isCurrentReadingPage()
    const completed = this.doc.isCompletedFullSummary
    return this._chapterCssClass('isummary-chapter', active, completed)
  }

  /**
   * Return ``true`` if "Full Summary" submenu is visible.
   */
  public get submenuVisible(): boolean {
    return this._submenuVisible
  }

  /**
   * Toggle the state of "Full Summary" submenu.
   */
  public toggleSubmenu(): void {
    this._submenuVisible = !this._submenuVisible
  }

  /**
   * Return the CSS for chapter icons in "Full Summary" submenu.
   */
  public chapterCss(page: ContentView): string {
    const active = this.doc.isCurrentPage(page)
    const completed = this.doc.isCompletedPage(page)

    if (this.doc.isPageOpenQuestions(page)) {
      return this._chapterCssClass('isummary-exercise', active, completed)
    } else {
      return this._chapterCssClass('isummary-chapter', active, completed)
    }
  }

  /**
   * Return the CSS for chapter wrapper in "Full Summary" submenu.
   */
  public chapterWrapperCss(page: ContentView): string {
    const active = this.doc.isCurrentPage(page)
    const completed = this.doc.isCompletedPage(page)

    let css = ''

    if (completed) {
      css += ' control-menu__sub-unit--finished'
    }
    if (active) {
      css += ' control-menu__sub-unit--active'
    }
    return css
  }

  /**
   * Return the link for "Full Summary" submenu chapter item.
   */
  public chapterLink(page: ContentView): Record<string, unknown> {
    return {
      name: this.doc.routeNames().page,
      params: {
        url_slug: this.doc.url_slug,
        page_url_slug: page.url_slug,
      },
    }
  }

  /**
   * Close TOC on mobile width.
   *
   * Full Summary submenu items are router-links, so the navigation logic is
   * covered by Vue.
   * Here we have an additional logic to close the TOC
   * on the mobile, because TOC overlays the content and it's not visible
   * whether the state changed.
   * On the desktop width, we still see the content, so we don't close the
   * TOC on Full Summary submenu item click.
   */
  public menuItemClick(): void {
    if (platform.isMobileWidth()) {
      this.hide()
    }
  }

  /**
   * Return the link for "Full Summary" submenu chapter item.
   */
  public bookmarkLink(bookmark: Bookmark): Record<string, unknown> {
    return {
      name: this.doc.routeNames().page,
      params: {
        url_slug: this.doc.url_slug,
        page_url_slug: bookmark.page_url_slug,
      },
      query: {
        highlight: bookmark.id,
      },
    }
  }

  /**
   * Trim text to max length.
   */
  public trimText(text: string, maxLength: number): string {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text
  }
}
